import { tooltipEnable } from './OtherFuncs';
import { setTimeout } from "timers";

const sidebar = $('#sidebar');
const objectFilter = $('#input-object-filter');

$(document).ready(() => {
    //window.addEventListener("resize", resizeSidebar);

    $(document).on("click", ".sidebar-objects a", function(e) {
        e.preventDefault();
    });
    
    $(document).on("click", ".node-a", function() {
        var wellName = $(this).find(".node-span").text().trim();
        var wellId = $(this).data("id");
        $("#WellName").focus();
        $("#WellName").val(wellName);
        $("#WellId").val(wellId);
        $("#sidebarModal").modal('hide');
    });

    $(document).on("click", "#clearWell", function () {
        $("#WellName").focus();
        $("#WellId").val(null);
        $("#WellName").val(null);
    });
    
    console.log(pb);
    
    //restoreSidebarState();
});

function restoreSidebarState() {
    var sidebarWidth = sessionStorage.getItem(`${sidebar.attr('id')}-width`);
    if (sidebarWidth != null) {
        sidebar.width(sidebarWidth);
        setTimeout(() => {
            $('.sidebar-object-height').width(sidebar.width());
            $('#auth-div').width(sidebar.width() - 70);
        }, 1000);
    }

    //for (var menu of $('.menu')) {
    //    if (sessionStorage.getItem($(menu).attr("id")) == "true") {
    //        $(menu).collapse('show');
    //    } else {
    //        $(menu).collapse('hide');
    //    }
    //}
}

export function loadSidebarObjects() {   
    if ($("#sidebarModal .modal-content").text().trim().length === 0) {
        $('#sidebarModal .preloader-box').addClass("show");
        $("#sidebarModal").show();
        
        let url = window.location.href;
        if (localStorage.getItem('url') !== null && localStorage.getItem('url') != "undefined") {
            if (localStorage.getItem('url') == url) {
                localStorage.removeItem("sidebarObject");
            }
        }

        localStorage.setItem('url', url);

        if (localStorage.getItem('sidebarObject') !== null && localStorage.getItem('sidebarObject') != "undefined") {
            var returnObj = JSON.parse(localStorage.getItem("sidebarObject"));
            $('#sidebar-object-list .modal-content').html(returnObj);

            onSidebarObjectListLoaded();

            console.log("Загрузка из LocalStorage");
        }
        else {
            $.get("/Objects/GetSidebarObjects", null, result => {
                $('#sidebar-object-list .modal-content').html(result);
            }).done(() => saveLocalStorage());

            console.log("Загрузка с сервера");
        }

        tooltipEnable();
    }
}

function saveLocalStorage() {
    onSidebarObjectListLoaded();
    var serialObj = JSON.stringify($("#sidebar-object-list .modal-content").html());
    localStorage.setItem('sidebarObject', serialObj);
}

function onSidebarObjectListLoaded() {
    ($('#sidebar-tree-table')).treegrid({
        treeColumn: 0,
        expanderExpandedClass: 'fa fa-chevron-down',
        expanderCollapsedClass: 'fa fa-chevron-right',
        indentTemplate: '<span class="treegrid-indent">&nbsp&nbsp&nbsp&nbsp&nbsp</span>'
    });

    ($('#node-2')).treegrid('collapse');

    [6].forEach(item => {
        ($(`tr.sidebar-object-tr[data-object-type-id="${item}"]`)).treegrid('collapse');
    });
    
    $('#sidebarModal .preloader-box').removeClass("show");
    //makeSidebarResizeble();
    //resizeSidebar();
}

function resizeSidebar() {
    let h = window.innerHeight;
    sidebar.height(h);

    //sidebar.width(sidebarWidth);
    setTimeout(() => {
        $('.sidebar-object-height').width(sidebar.width());
        $('#auth-div').width(sidebar.width() - 70);
    }, 1000);
}

function makeSidebarResizeble() {
    sidebar.resizable({
        maxWidth: 500,
        minWidth: 210,
        resize: (event, ui) => {
            //changeSidebarParams();
            setTimeout(() => changeSidebarParams(), 300);
        },
        stop: () => {
            sessionStorage.setItem(`${sidebar.attr('id')}-width`, $(sidebar).width().toString());
        },
        handles: "e",
    });
}

function changeSidebarParams() {
    var sidebarWidth = sidebar.width();
    $('.sidebar-object-height').width(sidebarWidth);
    $('#auth-div').width(sidebarWidth - 70);
    //$('.sidebar-object-tr').css('grid-template-columns', `${sidebarWidth - 10}px`);

    var authenticated = JSON.parse($('#auth-div').data('authenticated').toLowerCase());

    switch (true) {
        case (sidebarWidth < 100):
            $('#auth-div span').hide();
            if (authenticated) {
                $('#i-auth-out').addClass('fa fa-sign-out fa-lg');
            } else {
                $('#i-auth-in').addClass('fa fa-sign-in fa-lg');
            }
        case (sidebarWidth < 200):
            $('.sidebar-object-height').hide();
            $('.sidebar-header b').hide();
            $('#auth-div p').hide();
            break;
        default:
            $('.sidebar-object-height').show();
            $('.sidebar-header b').show();
            $('#auth-div p').show();
            $('#auth-div span').show();
            if (authenticated) {
                $('#i-auth-out').removeClass('fa fa-sign-out fa-lg');
            } else {
                $('#i-auth-in').removeClass('fa fa-sign-in fa-lg');
            }
    }

    var root = document.querySelector(':root');
    root.style.setProperty('--sidebar-width', `${sidebarWidth}px`);
}