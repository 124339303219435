//import 'jquery';
import $ from 'jquery';
global.jQuery = $;
global.$ = $;

//import '../lib/jquery/dist/jquery.js';
import '../lib/bootstrap/dist/js/bootstrap';
//import '../lib/bootstrap/dist/js/moment-with-locales.min.js';
//import '../lib/bootstrap/dist/js/bootstrap-datetimepicker.min.js';
import 'jquery-treegrid-webpack';
import 'webpack-jquery-ui';

import 'bootstrap-fileinput';
import 'bootstrap-fileinput/js/plugins/piexif';
//import 'bootstrap-fileinput/js/plugins/purify';
import 'bootstrap-fileinput/js/plugins/sortable';
import 'bootstrap-fileinput/js/locales/ru';
import 'bootstrap-fileinput/css/fileinput.min.css';

// dx ->
import '../css/devextreme/dx.common.css';
import '../css/devextreme/dx.light.css';

import '@wwwroot/css/style.css';

import './site';
import './OtherFuncs';
import './Sidebar';