//import { debug } from "console";
import { loadSidebarObjects } from "./Sidebar";

window.SrToPdf = SrToPdf;
window.loadSidebarObjects = loadSidebarObjects;
window.onTypeSelectorChange = onTypeSelectorChange;
window.eventSetState = eventSetState;
window.eventDelete = eventDelete;
window.eventListImg = eventListImg;
window.eventListDoc = eventListDoc;
window.logoutEvent = logoutEvent;
window.checkObjectLocalStorage = checkObjectLocalStorage;
window.toggleNode = toggleNode;
window.keyEnter = keyEnter;
window.filterTree = filterTree;
window.updateTreeWells = updateTreeWells;

window.decimalTextBoxInitialized = decimalTextBoxInitialized;
window.parseDecimalWithDifferentSeparators = parseDecimalWithDifferentSeparators;
window.setUpDecimalInput = setUpDecimalInput;

ajax_setup();

function SrToPdf(eventId) {
    window.open(pb + "/WellEvents/ShowPdf" + `?eventid=${eventId}`);
}

/**
* Осуществляет отображение модальной формы по работе с файлами.
*
* @this  {undefined}
* @param {number} eventId - id События.
* @param {boolean} getImages 
    true - работа с изображениями;
    false - работа с документами;
* @see $(document).ready
* @see viewEmagesLoaded
*/
function viewImages(eventId, getImages) {
    $.get('/WellEvents/ListFiles', { viewImages: getImages }, result => {
        document.getElementById('partial-list-files-modal').innerHTML = result;

        var viewModal = document.getElementById('list-files');

        $(viewModal).draggable({
            handle: '.modal-header'
        });

        $(viewModal).modal('show');
    }).done(() => viewEmagesLoaded(eventId, getImages));    
    
} // end_function viewImages (eventId, getImages)

/**
* Осуществляет инициализацию компонента bootstrap-fileinput (https://plugins.krajee.com/file-input/).
*
* @this  {undefined}
* @param {number} eventId - id События.
* @param {boolean} getImages 
    true - работа с изображениями;
    false - работа с документами;
* @see viewImages
*/
function viewEmagesLoaded(eventId, getImages) {
    var initialPreviewFileTypeValue = "other";
    var initialPreviewArray = [];
    var initialPreviewArrayImages = [];
    var initialPreviewConfigArray = [];
    var uploadUrlKrajee = "/WellEvents/AddFileKrajee?eventId=" + eventId + "&type=" + getImages;
    var allowedFileExtensions = ["txt", "pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "jpg", "jpeg", "tif", "tiff", "png", "gif", "bmp"];
    var language = "ru";

    // загрузка метаданных файлов
    //jQuery.ajax({
    //    url: "/WellEvents/ListFilesKrajee?eventId=" + eventId + "&viewImages=" + getImages,
    //    success: function (result) {
    //        // расширение URL до абсолютных путей
    //        result.initialPreview.forEach((element) => {
    //            //initialPreviewArray.push(window.location.origin + element);
    //        });
    //        result.initialPreviewConfig.forEach((element) => {
    //            var source = element;
    //            source.downloadUrl = window.location.origin + element.downloadUrl;
    //            initialPreviewConfigArray.push(source);
    //        });
    //        // result.initialPreviewData.forEach((element) => {
    //        //     initialPreviewArrayImages.push('<img src="data:image/bmp;base64,' 
    //        //         + element 
    //        //         + `" class="kv-preview-data ${getImages ? "file-preview-image" : "file-preview-other"} />`);
    //        // });
    //    },
    //    async: false
    //});

    if (getImages) {
        allowedFileExtensions = ["jpg", "jpeg", "tif", "tiff", "png", "gif", "bmp"];
    }
    initialPreviewFileTypeValue = getImages ? "image" : "other";
    //initialPreviewArray = initialPreviewArrayImages;

    // Проверка выбранного языка
    if (~getCookie('.AspNetCore.Culture').indexOf("en-US")) {
        //language = "en"; // раскомментировать при реализации английской версии
    }
    
    // инициализация компонента bootstrap-fileinput
    $("#input-files").fileinput({
        initialPreviewAsData: true, //!getImages,
        initialPreview: initialPreviewArray,
        initialPreviewConfig: initialPreviewConfigArray,
        initialPreviewFileType: initialPreviewFileTypeValue,
        allowedFileExtensions: allowedFileExtensions,
        uploadUrl: uploadUrlKrajee,
        overwriteInitial: false,
        language: language,
        maxFileSize: getImages ? 6144 : 0,
        maxFileCount: getImages ? 1 : 0,
        maxTotalFileCount: getImages ? 3 : 0,
        initialPreviewCount: getImages ? 0 : 0,
        validateInitialCount: getImages ? true : false
    });

    $("#input-files").on("fileuploaded", function (event, data, previewId, index) {
        $(".modal").modal("hide");
        window.location.reload(false);
    });
} // end_function viewEmagesLoaded (eventId, getImages)


/**
* Осуществляет поиск по записям Событий.
*
* @this  {undefined}
* @see $(document).ready
*/
function startSearch() {
    var phrase = $('#search-input').val();

    // загрузка результатов поиска
    jQuery.ajax({
        url: "/WellEvents/SearchEvents?phrase=" + phrase,
        success: function (result) {
            result.eventsLinks.forEach((element) => {
                $("#search-results").append('<p><a href="' + element + '">Событие</a></p>');
            });
        },
        async: false
    });
} // end_function startSearch ()


// Main thread
$(document).ready(() => {

    onTypeSelectorChange($('#evenEditForm #type-selector'));

    $(document).on('click', '.button-expand', function () {
        $('.sidebar-objects .node').addClass('open');
    });

    $(document).on('click', '.button-collapse', function () {
        $('.sidebar-objects .node').removeClass('open');
    });

    $(document).ajaxError((event, request, settings) => {
        console.log({ event, request, settings });
        switch (request.status) {
            case 401:
                window.location.replace('/Account/Login');
                break;
            default:
                let $modal;
                $modal = $('#alertModal');
                if ($modal) {
                    $modal.find('.modal-text').text('Ошибка при сохранении');
                    $modal.find('.modal-list').html(`${request.responseText} (${request.status} ${request.statusText})`);
                    $modal.find('button').prop("disabled", false);
                }
                break;
        }
    });
});

$('#search-input').keypress(function (event) {
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
        startSearch();
    }
});

$('#search-input-button').click(element => {
    startSearch();
}); // end_Main thread

function eventDelete(element) {
    var eventId = element.dataset.eventId;
    window.elDelEvent = element;
    $.get('/WellEvents/DeleteEvent', { eventId: eventId }, result => {
        document.getElementById('partial-del-event-modal').innerHTML = result;

        var viewModal = document.getElementById('del-event');

        $(viewModal).draggable({
            handle: '.modal-header'
        });
        $(viewModal).modal('show');

        $('#del-EventPost').click(element => {
            $(viewModal).modal('hide');

            viewModal = document.getElementById('myModal');
            $(viewModal).draggable({
                handle: '.modal-header'
            });

            $(viewModal).find("#myModalLabel").text('Удаление данных...');
            $(viewModal).modal('show');

            $.ajax({
                url: "/WellEvents/DeleteEventPost",
                type: "POST",
                data: { eventId: eventId }
            }).done(result => {
                $(viewModal).modal('hide');
                window.elDelEvent.closest('tr').remove();
            }).fail(exception => {
                $(viewModal).modal('hide');
                alert("Ошибка удаления записи");
            });
        });
    });
}

function eventListImg(element){
    var eventId = element.dataset.eventId;

    viewImages(eventId, true);
}

function eventListDoc(element){
    var eventId = element.dataset.eventId;

    viewImages(eventId, false);
}

function eventSetState(element) {
    var eventId = element.dataset.eventId;
    var newState = element.dataset.eventState;
    var url = element.dataset.eventUrl;
    window.elSubmitEvent = element;
    $.get('/WellEvents/SubmitEvent', { eventId: eventId, state: newState }, result => {
        document.getElementById('partial-submit-event-modal').innerHTML = result;

        var viewModal = document.getElementById('submit-event');
        $(viewModal).draggable({
            handle: '.modal-header'
        });
        $(viewModal).modal('show');

        $('.submit-EventPost').click(element => {
            $(viewModal).modal('hide');

            var Comment = $('#Comment').val();

            viewModal = document.getElementById('myModal');
            $(viewModal).draggable({
                handle: '.modal-header'
            });

            $(viewModal).find("#myModalLabel").text('Сохранение данных...');
            $(viewModal).find("#returnBtn").hide();
            $(viewModal).modal('show');

            $.ajax({
                url: url,
                type: "POST",
                data: { id: eventId, state: newState, comment: Comment }
            }).done(result => {
                window.location.reload(false);
                $(viewModal).modal('hide');
            }).fail(exception => {
                $(viewModal).modal('hide');
                alert("Ошибка изменения статуса");
            });
        });
    });

    onTypeSelectorChange($('#evenEditForm #type-selector'));
}

function onTypeSelectorChange(selector) {
    if($(selector).val() === "49"){
        $('#evenEditForm #negative-label').hide();
        $('#evenEditForm #negative-result-label').hide();
        $('#evenEditForm #positive-label').show();
        $('#evenEditForm #positive-result-label').show();
    } else {
        $('#evenEditForm #negative-label').show();
        $('#evenEditForm #negative-result-label').show();
        $('#evenEditForm #positive-label').hide();
        $('#evenEditForm #positive-result-label').hide();
    }
}

function logoutEvent() {
    $.ajax({
        url: "/Account/Logout",
    }).done(result => {
        if (result.close) {
            window.close(); // закрываем вкладку
        }
        location.href = pb + "/"; // возвращаемся на страницу авторизации
    });
}

function getCookie(name) {
    var r = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    if (r) return r[2];
    else return "";
}

function ajax_setup() {
    $.ajaxPrefilter(function( options ) {
        if ( options.crossDomain
            || options.url.startsWith("http")) {
            return;
        }
        options.url = pb + options.url;
    });
}

function toggleNode(element) {
    $(element).closest(".node").toggleClass("open");
}

function keyEnter(event) {
    if(event.keyCode === 13){
        event.preventDefault();
        filterTree();
    }
}

function filterTree(clean = false) {
    const $node = $("#tree .node");
    const $inputFilter = $('#input-object-filter');
    const $notFound = $("#tree .not-found");

    if ($inputFilter.val() === '' || clean) {
        $inputFilter.val('');
        $notFound.addClass("d-none");
        $node.addClass("open").removeClass("d-none");
        return;
    }

    let founded = $node.filter((index, element) => {
        return element.textContent.toLowerCase().includes($inputFilter.val().toString().toLowerCase());
    });

    let fullList = [];
    founded.each((index, element) => {
        GetParentObject($(element).data("id"), fullList);
    });

    $node.each((index, element) => {
        let id = $(element).data("id");
        if (fullList.indexOf(String(id)) > -1) {
            element.classList.add("open");
            element.classList.remove("d-none");
        } else {
            element.classList.add("d-none");
            element.classList.remove("open");
        }
    });

    if (fullList.length === 0) $notFound.removeClass("d-none");
    else $notFound.addClass("d-none");
}

function updateTreeWells() {
    $('#sidebarModal .modal-content').removeClass("loaded").empty();
    $('#sidebarModal .preloader-box').addClass("show");

    if (checkObjectLocalStorage("sidebarReportObject")) {
        localStorage.removeItem("sidebarReportObject");
    }

    loadSidebarObjects();
}

function checkObjectLocalStorage(key) {
    const object = localStorage.getItem(key);
    return object !== null && object !== "undefined";
}

/**
 * @return {undefined}
 */
function GetParentObject(id, fullList) {
    const nodeObject = $(`#tree .node[data-id='${id}']`),
        parentId = nodeObject.data('parent-id');
    fullList.push(id.toString());

    if (parentId === '#' || parentId === '') {
        return;
    }

    return GetParentObject(parentId, fullList);
}

function decimalTextBoxInitialized(data) {
    const precision = 1;
    const decimalInput = data.element[0];
    setUpDecimalInput(decimalInput, precision);
}

function setUpDecimalInput(decimalInput, defaultPrecision = 1) {
    let precision = defaultPrecision;
    let allowNegative = false;
    const input = decimalInput.tagName !== 'INPUT' ? decimalInput.querySelector(".dx-texteditor-input") : decimalInput;
    if (input) {
        const dataSet = input.dataset;
        if (dataSet) {
            precision = dataSet.precision ? dataSet.precision : defaultPrecision;
            allowNegative = dataSet.allowNegative;

        }
        if (input.value) {
            input.value = input.value.replace(',', '.');
        }
    }

    const initVal = input.value.trim();
    const formattedVal = parseDecimalWithDifferentSeparators(initVal, precision, allowNegative);
    input.value = formattedVal;

    decimalInput.addEventListener("input",
        inputEvent => {
            const val = inputEvent.target.value.trim();


            const parsedVal = parseDecimalWithDifferentSeparators(val, precision, allowNegative);
            inputEvent.target.value = parsedVal;
        }
    );
}

function parseDecimalWithDifferentSeparators(val, precision = 1, allowNegative) {
    let sep = '';
    if (!val) {
        return '';
    }

    let isNegative = val.indexOf('-') === 0;

    if (val.indexOf(',') > -1) {
        sep = ',';
        val = val.replace(/[^\d,]/g, '');
    } else if (val.indexOf('.') > -1) {
        sep = '.';
        val = val.replace(/[^\d.]/g, '');
    }

    if (!sep) {
        res = val.replace(/\D+/g, "");
        if (allowNegative && isNegative) {
            res = '-' + res;
        }
        return res;
    }

    const split = val.split(sep);
    if (split.length === 1) {
        return split[0];
    }

    let res;
    if (split[1].length > 0) {
        let fractPart = split[1];
        if (split[1].length > precision) {
            fractPart = split[1].substring(0, precision);
        }
        res = Number(split[0]) + '.' + fractPart;
    } else {
        res = Number(split[0]) + '.';
    }

    if (allowNegative && isNegative) {
        res = '-' + res;
    }

    return res;
}